import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Home";
import loadable from "@loadable/component";
import ErrorBoundary from "../ErrorBoundary"

import GraphApp from "../GraphApp"

const LoadableZombiesPage = loadable(() => import("../ZombiesPage"), {
  fallback: <div></div>
});

const LoadableUrGame = loadable(() => import("../UrGame"), {
  fallback: <div></div>
});

const LoadableCubesGame = loadable(() => import("../CubesGame"), {
  fallback: <div></div>
});

const RedirectSite = () => {
	//window.location.href = "/CubesGame/CubesGame.html";
	return <div><a href="/CubesGame/CubesGame.html">click to play</a></div>;
};

function App() { 
	return (
	  <Router basename="/">
		<Routes>
		  <Route exact path='/' element={<Home/>} />
		  <Route path='/test' element={<h1> test is good </h1>} />
		  <Route path='/GraphApp' element={<><GraphApp/><ErrorBoundary/></>} />
		  <Route path='/ZombiesGame' element={<><LoadableZombiesPage/><ErrorBoundary/></>} />
		  <Route path='/UrGame' element={<><LoadableUrGame/><ErrorBoundary/></>} />
		  <Route path='/CubesGoRight' element={<><LoadableCubesGame/><ErrorBoundary/></>} />
		  <Route exact path='/Cubes' element={<RedirectSite/>} />
		  <Route path='*' element={<><h3>404: Page not found</h3><ErrorBoundary/></>} />
		</Routes>
	  </Router>
    );
};


export default App;
