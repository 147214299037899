import React from 'react';
import { Link } from "react-router-dom";
import loadable from '@loadable/component';
import './index.css';

const LoadableGraph = loadable(() => import("./GraphComponent"), {
  fallback: <div></div>
});

export default () => (
      <>
        <div className="bg_banner">
          <main className="container">
            <Link to="/" className="btn btn-link">
              Home
            </Link>
            <a
              className='btn btn-link'
              href="https://github.com/Sphinx111/Tetra-Build-1"
              target='_blank'
              rel="noopener"
              aria-label='Github'
              >
              Source Code
            </a>
          </main>
        </div>
        <div className="primary-color">
          <section className="jumbotron jumbotron-fluid text-center">
            <div className="container py-3">
              <h2 className="display-4">Tetra Radio Project</h2>
              <p className="lead">
                Technology Used: C++, Python (React on Rails Front-end)
              </p>
              <p className="lead text-muted">
                Using a simple USB Radio receiver, anyone can receive TETRA radio transmissions.
                Without any decryption, metadata can be read and processed. This project attempts to demonstrate just how much valuable data is transmitted without any encryption.
                The display below shows a sample 24-hr trace of how many unique Tetra radio users are active in a chosen area.
              </p>
            </div>
          </section>
          <div className="graphMargin">
            <LoadableGraph />
          </div>
          <section>
            <div className="container py-3">
              <h3>How it works</h3>
                <p>
                Radio transmissions are initially received through Osmocom's RTL-SDR package, and fed into "GNURadio", a linux program that allows users to visually build a data-handling route to process raw radio signals.
                GNURadio outputs a bitstream by UDP, into our C++ decoder program. The decoder applies the encoding standards from ETSI EN 300 392-2 (TETRA V+D Air Interface) edited to provide output just the most important metadata as JSON.
                </p>
                <p>
                The JSON Output is then passed to a python program which will handle the big data analysis, and stores new objects in a Sqlite3 database. This part of the program is still being built upon.
                For the graph displayed above, only a very simple analysis is used, taking values from the database for unique users, and providing those to a React front-end, using ChartJS for the graph visualisation.
                </p>
                <p>
                The graph above deliberately omits information on the exact location of data being displayed, and does not show a "live" version of the data, for public safety reasons. In the UK, Tetra radio is almost exclusively used by Emergency services.
                </p>
            </div>
          </section>
        </div>
      </>
);
