import React from "react";
import { Link } from "react-router-dom";
import './index.css';

export default () => (
	<div className="homeContainer">
		<div className="AvatarImg">
		  <img
			src={require('./assets/images/5894910.png')}
			alt=""
			loading="lazy"
		  />
		</div>
		<div className="HeroBanner">
		  <div className="jumbotron jumbotron-fluid bg-transparent">
			<div className="container secondary-color">
			  <h1 className="display-4 secondary-color">Edward's Testing Ground</h1>
			  <p className="lead">
				A personal display / testing page for early projects
			  </p>
			</div>
		  </div>
		</div>
		<div class="container buttonGroup">
			<div class="col marginTop 16px">
				<div class="row" >
				  <Link
					to="/GraphApp"
					className="btn btn-lg custom-button px-2"
					role="button"
				  >
					Tetra Metadata (C++, Python)
				  </Link>
				</div>
				<div class="row">
				  <Link
					to="/ZombiesGame"
					className="btn btn-lg custom-button px-2"
					role="button"
				  >
					Zombies! (Java)
				  </Link>
				</div>
				<div class="row">
				  <Link
					to="/UrGame"
					className="btn btn-lg custom-button px-2"
					role="button"
				  >
					Royal Ur (React)
				  </Link>
				</div>
				<div class="row">
				  <Link
					to="/CubesGoRight"
					className="btn btn-lg custom-button px-2"
					role="button"
				  >
					CubesGoRight
				  </Link>
				</div>
			</div>
	   </div>
  </div>
);
